import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";

import "./index.css"

export const Footer = ({isOnContactPage}) => {
  	return (
		<div className="app-footer">
			<div>
				{/* Create a span of photos and put the calligraphy stay inspired on top along with a link to IG.
					The IG should be @something_pictured */}
			</div>
			{/* If not contact page, add a banner: Tell your story and link to Contact page */}
			{
				!isOnContactPage && 
				<div className="flexbox">
					<p className="promo">Tell your story</p>
					<a href="/contact" class="contact-button">CONTACT US</a>
				</div>
			}
			<div className="socials">
				<span className="icon-container">
					<a href="https://www.instagram.com/something_pictured/">
						<FaInstagram className="icon" style={{marginTop: "-2px"}} fill="#e5c071" size={23}/>
					</a>
				</span>
				<span className="icon-container">
					<a href="https://www.facebook.com/profile.php?id=61554095546928/">
						<FaFacebookF className="icon" fill="#e5c071" size={18}/>
					</a>
				</span>
				<span className="icon-container">
					<FaPinterestP className="icon" fill="#e5c071" size={20}/>
				</span>
			</div>
		</div>
	);
};
