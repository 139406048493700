import { Component } from "react";
import '../../App.css';
import './index.css';
import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";

class Offerings extends Component {
    constructor(props) {
        super(props);
            this.state = {
        }
    }

    render() {
        return (
            <div className="app-page">
                <Header></Header>

                <div className="content">
                    <div className="column">
                        <div className="block">
                            <img alt="" loading="eager" src={require("./wedding.jpg")}></img>
                        </div>
                        <div className="text-block">
                            <p className="title">WEDDINGS</p>
                            <p>Complimentary engagement session</p>
                            <p>Engraved USB with high resolation images</p>
                            <p>Luxury album with 10 8x10 prints</p>
                            <p>Professional consultations and vision board</p>
                            <p>Six to ten hours of coverage starting at $2500</p>
                        </div>
                    </div>

                    <div className="column">
                        <div className="block">
                            <img alt="" loading="eager" src={require("./elope.jpg")}></img>
                        </div>
                        <div className="text-block">
                            <p className="title">ELOPEMENT</p>
                            <p>Engraved USB with high resolation images</p>
                            <p>Luxury album with 5 8x10 prints</p>
                            <p>Professional consultations and vision board</p>
                            <p>Three to four hours of coverage starting at $1500</p>
                        </div>
                    </div>

                    <div className="column">
                        <div className="block">
                            <img alt="" loading="eager" src={require("./engagement.jpg")}></img>
                        </div>
                        <div className="text-block">
                            <p className="title">LIFESTYLE</p>
                            <p>Opportunities to document your special memories in life from the 
                                proposal moment and engagement session to maternity and family portraits. </p>
                            <p>Engraved USB with high resolation images</p>
                            <p>Professional consultations and vision board</p>
                            <p>Hourly coverage starting at $350</p>
                        </div>
                    </div>

                </div>

            {/* 
                Some sample packages here for weddings with what is included
                Elopement: 3-4 hours starting at base price $1200
                Complimentary engagement session
                Engraved USB with high res images 
                Option to purchase luxury customised printed album with 10 6x8 pictures
                Wedding: 6-10 hours starting at base price $1800
                Complimentary engagement session
                Engraved USB with high res images 
                Option to purchase luxury customised printed album with 30 pictures
                Lifestyle/Engagement: $250 per hour
                Minis: $150 
            */}

                <Footer></Footer>
            </div>
        );
    }
}

export default Offerings;
