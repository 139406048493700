import emailjs from "@emailjs/browser";
import { Component } from "react";
import './index.css';
import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Contact extends Component {
    constructor(props) { 
        super(props); 
        this.state = {
            fullName: "",
            partnerName: "",
            email: "",
            phone: "",
            date: "",
            location: "",
            inquiryType: "Engagement",    // wedding, elopement (<4 hours), family portrait, beauty/boudoir, maternity, engagement
            story: "",
        }; 
        this.updateName = this.updateName.bind(this);
        this.updatePartnerName = this.updatePartnerName.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updatePhone = this.updatePhone.bind(this);
        this.updateLocation = this.updateLocation.bind(this);
        this.updateDate = this.updateDate.bind(this);
        this.updateInquiryType = this.updateInquiryType.bind(this);
        this.updateStory = this.updateStory.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    updateName(e) {
        this.setState({fullName: e.target.value});
    }

    updatePartnerName(e) {
        this.setState({partnerName: e.target.value});
    }

    updateEmail(e) {
        this.setState({email: e.target.value});
    }

    updatePhone(e) {
        this.setState({phone: e.target.value});
    }

    updateLocation(e) {
        this.setState({location: e.target.value});
    }

    updateDate(e) {
        this.setState({date: e.target.value});
    }

    updateInquiryType(e) {
        this.setState({inquiryType: e.target.value});
    }

    updateStory(e) {
        this.setState({story: e.target.value});
    }

    onSubmit(e) {
        const formData = {
            fullName: this.state.fullName, partnerName: this.state.partnerName, email: this.state.email, phone: this.state.phone,
            date: this.state.date, location: this.state.location, inquiryType: this.state.inquiryType, story: this.state.story
        };

        e.preventDefault();
        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_CONTACT_FORM_TEMPLATE_ID, 
            formData,
            {
                publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
            }
        ).then((result) => {
            console.log(result.text);
            toast("✨ All set! We'll be in touch soon.",
                {
                    hideProgressBar: true,
                    autoClose: 3000
                }
            );
            e.target.reset();
        },
        (error) => {
            console.log(error);
            toast("Sorry! Your request cannot be completed at this time.");
        }
        );
        
    }

    render() {
        // Add a Captcha
        return (
            <div className="app-page">
                <Header></Header>

                <div className="contact-form">
                    <div className="form-column img-col">
                        <img alt="" loading="eager" src={require("./photo.jpg")}></img>
                    </div>
                    <div className="form-column form-col">
                        <form id="contact-form" onSubmit={this.onSubmit} >
                            <div className="input-block">
                                <label>Full Name</label>
                                <input maxLength={40} onChange={this.updateName} required></input>
                            </div>

                            <div className="input-block">
                                <label>Partner's Name</label>
                                <input maxLength={40} onChange={this.updatePartnerName}></input>
                            </div>
                            
                            <div className="input-block">
                                <label>Phone Number</label>
                                <input placeholder={'012-345-6789'} type="tel" id="phone" name="phone" onChange={this.updatePhone} required/>
                            </div>

                            <div className="input-block">
                                <label>Email Address</label>
                                <input type="email" maxLength={40} onChange={this.updateEmail} required></input>
                            </div>

                            <div className="input-block">
                                <label>Inquiry Type</label>
                                <select onChange={this.updateInquiryType}>
                                    <option value="engagement">Engagement</option>
                                    <option value="wedding">Wedding</option>
                                    <option value="elopement">Elopement (&lt;4 hours)</option>
                                    <option value="family">Family Portrait</option>
                                    <option value="boudoir">Beauty / boudoir</option>
                                    <option value="wedding">Maternity</option>
                                </select>
                            </div>

                            {/* Show date only if the inquiry type is wedding/elopement */}

                            <div className="input-block">
                                <label>Event Date</label>
                                <input type="date" onChange={this.updateDate} required></input>
                            </div>

                            <div className="input-block">
                                <label>Event Location</label>
                                <input placeholder={'Venue Name or City, State'} maxLength={40} onChange={this.updateLocation} required></input>
                            </div>

                            <div className="input-block">
                                <label>Tell us your story or your vision for this event.</label>
                                <textarea maxLength={2000} onChange={this.updateStory}></textarea>
                            </div>

                            <div className="input-block">
                                <button form={"contact-form"} type={"submit"} className="form-submit">
                                    Inquire
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <ToastContainer/>

                <Footer isOnContactPage={true} ></Footer>
            </div>
        );
    }
}

export default Contact;
