import { useEffect } from "react";
import emailjs from "@emailjs/browser";
import './App.css';
import Home from "./Pages/Home";
import Offerings from "./Pages/Offerings";
import Contact from "./Pages/Contact";

import { BrowserRouter, Routes, Route } from "react-router-dom";

/*
  Set up 2 emails 
  info@aurephotography.com (BTS info.aurephotography@gmail.com): used for client communications
  promo@aurephotography.com (BTS promo.aurephotography@gmail.com): used for mini session promotions, 
  congrats on anniversaries and anniversary session promotions
  Read method 2 from https://themeisle.com/blog/use-gmail-with-your-own-domain-name/#free-smtp
  

  The form on Contact page will send an email to info@aurephotography.com with client details (using Nodemailer on BE).
  I will log into info@aurephotography.com and create new email to client's email.

  For client images, use react-photo-gallery from React and integrate with SQL.
  We'll need to build a SQL database with schema:
  CLIENT TABLE
  ID                                        Images range        Password
  A & B Location Wedding/Engagement/etc.    00000000-00000075   lovebirds12/yoursforever/mytreasure

  IMAGE TABLE
  ID        Image                 
  00000000  Low res image blob
  00000001  Low res image blob
  ...
  */


function App() {
  useEffect(() => emailjs.init( process.env.REACT_APP_EMAILJS_PUBLIC_KEY ), []);

  return (
    <BrowserRouter>
        <Routes>
            <Route index element={<Home />} />
            <Route path="offerings" element={<Offerings />} />
            <Route path="contact" element={<Contact />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;


/*
Âme Photography
MEI Photography
Maktub 
Sonhar (dream)

*/

/*
Remember that wherever your heart is, there you will find your treasure.
Maktub. If I am really a part of your dream, you'll come back one day.
Everyone on earth has a treasure that awaits him.
To realize one's destiny is a person's only real obligation.
Regardless of who you are or from where you belong, when you really want something, it’s because that originated in the soul of the universe and that is our true mission on earth. 
Our only duty is to realize our destiny.
When you want something, all the universe conspires in helping you to achieve it.
*/

/*
Then it was as if time stopped, and the Soul of the World appeared with all its strength before the boy. […] 
There was the pure language of the world, without explanations, because the Universe did not need explanations 
to continue its path in endless space. All the boy understood at that moment was that he was before the woman 
of his life, and without any need for words, she must know this too […]. And the boy sat for a long time next 
to the well, understanding that one day the Levant had left the perfume of that woman on his face, and that he 
had already loved her before he even knew she existed, and that his love for her would make him find all the 
treasures of the world.
tesouro - treasure
Alma - Soul
Levant - wind
*/

/*
Aurélio (Portuguese/Latin) - Derived from "aurum," meaning gold, symbolizing the pursuit of treasure and personal legend.
Aure (French) - golden
Aure (some African cultures) - wedding/marriage
*/