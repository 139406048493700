import React from "react";
import { Logo } from "./Logo";
import { NavBar } from "./NavBar";

import "./index.css"

export const Header = () => {
  return (
    <div className="app-header">
      <Logo></Logo>
      <NavBar></NavBar>
    </div>
  );
};
